<template>
    <div class="common-table search-result">
        <div class="common-table__title">按药品分类分析</div>
        <div v-if="isEmpty" class="search-result__container" style="margin-top: 20px;padding-top: 100px;">
            <ts-empty></ts-empty>
        </div>
        <div v-else class="search-result-body">
            <el-tabs v-model="tab" type="border-card">
                <el-tab-pane name="sale"><span slot="label">{{ pageMap.sale }}</span></el-tab-pane>
                <el-tab-pane name="trend"><span slot="label">{{ pageMap.trend }}</span></el-tab-pane>
            </el-tabs>
        </div>

        <div class="search-result__container" v-if="loaded && !isEmpty">
            <div class="search-result__panel" :class="{show: tab === 'sale'}">
                <treat-big-sale v-if="finished.sale" :visit-id="visitId" @empty="isEmpty = true" ref="bigSale"></treat-big-sale>
            </div>
            <div class="search-result__panel" :class="{show: tab === 'trend'}">
                <treat-big-trend v-if="finished.trend" :visit-id="visitId" @empty="isEmpty = true"></treat-big-trend>
            </div>
        </div>
    </div>
</template>

<script>
    import TreatBigSale from './TreatBigSale'
    import TreatBigTrend from './TreatBigTrend'
    export default {
        components: {TreatBigSale, TreatBigTrend},
        data() {
            return {
                visitId: 0,
                pageId: '',
                pageName: '',
                pageMap: {
                    sale: '药品大类历年销售',
                    trend: '药品大类历年销售趋势',
                },

                tab: 'sale',
                loaded: false,
                finished: {
                    sale: false,
                    trend: false,
                },
                isEmpty: false,
            }
        },
        created() {
            this.init();
        },
        mounted() {
            this.$nextTick(() => {
                this.loaded = true
            })
        },
        watch: {
            '$route': function () {
                this.init()
            },
            tab: {
                handler(val) {
                    this.finished[val] = true

                    this.visitId = new Date().getTime()
                    this.pageId = val
                    this.pageName = '药品分类分析-' + this.pageMap[val]

                    this.$help.socket.send(this)
                },
                immediate: true
            },
        },
        methods: {
            init() {
                this.isEmpty = false
            },
        },
    }
</script>